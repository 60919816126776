import React, { useEffect } from "react"
import { graphql, Link } from "gatsby";

import Layout from '../components/layout'
import "./../components/DynamicPage.css"




function GeneralTemplate(props) {
    let data = props.data;
    
    console.log(data)
    // function constructMetaData(page, currentPage, language) {
    //     let payload = {
    //         title: page.seo.title,
    //         description: page.seo.metaDesc,
    //         canonical: `https://ficon.gr${currentPage}`,
    //         fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
    //         fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
    //         fbUrl: `https://ficon.gr${currentPage}`,
    //         fbType: "website",
    //         locale: language.toLowerCase(),
    //         image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
    //     }
    //     return payload
    // }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                globalInfo={data.wpTemplate.globalInformation}
                mode="dark"
            // locale={props.pageContext.language}
            // metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
            // services={props.pageContext.servicesCategories}
            >
                <div className="container-cs mx-auto py-16 md:py-32 px-4" >
                    <h1 className="service-h1">{data.wpPage.title}</h1>

                    <div className="service-editor" dangerouslySetInnerHTML={{ __html: data.wpPage.content }}></div>
                </div>
            </Layout>
        </div>
    )
}

export default GeneralTemplate;

export const pageQuery = graphql`query GET_GENERAL_PAGE($id: String, $templateId: String) {
    wpPage(id: { eq: $id}){
        seo {
            canonical
            title
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphImage {
                sourceUrl
            }
        }
        title
        content
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }
}
`

